<template>
  <div>
    <v-app-bar
      color="green darken-2"
      dark
      app
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      fixed
    >
      <v-icon @click="layoutChangeSidebar()">fa-bars</v-icon>
      <v-row align="center" class="ml-1">
        <v-btn text small @click="navigateTo(home)" class="title  white--text">
          Simplificador
        </v-btn>
        <div>
          <span v-if="currentUser" class="hidden-sm-and-down white--text title">
            Olá, {{ currentUser.name }}
          </span>
        </div>
      </v-row>
      <v-row justify="end" align="center">
        <b class="hidden-sm-and-down">Configurações:</b>
        <v-menu :nudge-width="100">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" :icon="breakpoint">
              <v-icon dark>fa-store-alt</v-icon>
              <span class="hidden-sm-and-down ">Sua Empresa</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, i) in store"
              :key="i"
              @click="navigateTo(item)"
            >
              <v-list-item-title>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-title>
              <v-list-item-action style="min-width: 36px;">
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu :nudge-width="100">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" :icon="breakpoint">
              <v-icon dark>fa-cog</v-icon>
              <span class="hidden-sm-and-down">Sua Conta</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in settings"
              :key="i"
              @click="navigateTo(item)"
            >
              <v-list-item-title>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-title>
              <v-list-item-action style="">
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="divider--bar"></div>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { authComputed } from '@state/helpers'

export default {
  computed: {
    ...authComputed,
    breakpoint() {
      if (this.$vuetify.breakpoint.smAndDown) return true
    },
  },
  data() {
    return {
      home: {
        nameRouter: 'home',
      },
      store: [
        {
          nameRouter: 'Company',
          text: 'Dados da Empresa',
        },
        {
          nameRouter: 'SocialContract',
          text: 'Contrato Social',
        },
        {
          nameRouter: 'Partners',
          text: 'Sócios',
        },
        {
          nameRouter: 'TaxDocument',
          text: 'Documentos Contábeis',
        },
        {
          nameRouter: '',
          text: 'Assista ao tutorial',
          icon: 'ondemand_video',
          tour: true,
          urlVideo: 'https://www.youtube.com/watch?v=5YLqIR4JGPQ',
        },
      ],
      settings: [
        {
          text: 'Extrato de Utilização',
          nameRouter: 'UsageStatement',
        },
        {
          text: 'Forma de Pagamento',
          nameRouter: 'PaymentForm',
        },
        {
          text: 'Dados do Plano',
          nameRouter: 'PlanData',
        },
        {
          text: 'Contrato de Serviço',
          nameRouter: 'ContractServices',
        },
        {
          text: 'Alterar senha',
          nameRouter: 'ResetPassword',
        },
        {
          text: 'Cancelar Conta :(',
          nameRouter: 'AccountCancel',
        },
        {
          nameRouter: '',
          text: 'Assista ao tutorial',
          icon: 'ondemand_video',
          tour: true,
          urlVideo: 'https://www.youtube.com/watch?v=TMhlDVdl630',
        },
        {
          text: 'Sair',
          nameRouter: 'logout',
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      layoutChangeSidebar: 'layout/flipflopSidebar',
      dialogsChangeVideoShow: 'dialogs/changeVideoShow',
    }),
    navigateTo(item) {
      /* eslint-disable no-alert */
      if (item.tour) {
        this.dialogsChangeVideoShow({ status: true, url: item.urlVideo })
      }
      if (item.nameRouter) {
        this.$router.push({ name: item.nameRouter }).catch(() => {})
      }
    },
  },
}
</script>
<style scoped lang="sass" scoped>
.v-btn
  text-transform: capitalize
  .v-icon
    margin-right: 10px
</style>
